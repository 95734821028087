// =====================================================================================================================
// Ish icons - fontello ( !copy paste only icons starting on line 62! )


@font-face {
    font-family: 'ish-fontello';
    src: url('../font/ish-fontello.eot?72794789');
    src: url('../font/ish-fontello.eot?72794789#iefix') format('embedded-opentype'),
    url('../font/ish-fontello.woff2?72794789') format('woff2'),
    url('../font/ish-fontello.woff?72794789') format('woff'),
    url('../font/ish-fontello.ttf?72794789') format('truetype'),
    url('../font/ish-fontello.svg?72794789#ish-fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}


/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ish-fontello';
    src: url('../font/ish-fontello.svg?72794789#ish-fontello') format('svg');
  }
}
*/


[class^="ish-icon-"]:before, [class*=" ish-icon-"]:before {
    font-family: "ish-fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}


// ---------------------------------------------------------------------------------------------------------------------
// Paste new icons here
.ish-icon-up:before { content: '\e800'; } /* '' */
.ish-icon-left:before { content: '\e801'; } /* '' */
.ish-icon-right:before { content: '\e802'; } /* '' */
.ish-icon-article:before { content: '\e803'; } /* '' */
.ish-icon-search:before { content: '\e804'; } /* '' */
.ish-icon-list-add:before { content: '\e805'; } /* '' */
.ish-icon-cancel:before { content: '\e806'; } /* '' */
.ish-icon-lamp:before { content: '\e807'; } /* '' */
.ish-icon-flag:before { content: '\e808'; } /* '' */
.ish-icon-right-open:before { content: '\f006'; } /* '' */
.ish-icon-globe-inv:before { content: '\f019'; } /* '' */
.ish-icon-twitter:before { content: '\f099'; } /* '' */
.ish-icon-facebook:before { content: '\f09a'; } /* '' */
.ish-icon-dribbble:before { content: '\f17d'; } /* '' */
.ish-icon-behance:before { content: '\f1b4'; } /* '' */