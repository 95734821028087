// =====================================================================================================================
// Layout


// ---------------------------------------------------------------------------------------------------------------------
// Body wrapper
.ish-wrapper {
    max-width: $page-width;
    display: flex;
    position: relative;
    left: 0;
    @include transition-on();
    min-height: 100vh;

    &__inner {
        margin-left: $content-margin-left;
        margin-right: $content-margin-right;
        flex-basis: $content-width;

        > .ish-row {
            margin-left: 0;
            margin-right: 0;
        }

        .ish-fullrow {
            margin-left: -$content-margin-left;
        }
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Sidebar
.ish-sbar {

    position: fixed;
    top: 0;
    left: -$sidebar-width - $sidebar-btn-width;
    width: $sidebar-width;
    height: 100%;
    z-index: 1002;
    max-width: calc( 100% - 30px );
    @include transition-on();

    &__inner {
        width: $sidebar-width+20px;
        height: 100%;
        max-width: 100%;
        overflow: auto;
        padding: $general-gap/1.3;
    }

    &__logo {
        @include main-font(600, 30px, 40px, -2px);
        text-decoration: none;
        margin-bottom: $general-gap;
        display: inline-block;
    }

    p {
        margin-bottom: $general-gap;

        a {
            @include link-underline-on();
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1001;
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjg4RDYyODUxOUI3RTExRTY4QTA1QzU5QTNDREM1Nzg4IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjg4RDYyODUyOUI3RTExRTY4QTA1QzU5QTNDREM1Nzg4Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6ODhENjI4NEY5QjdFMTFFNjhBMDVDNTlBM0NEQzU3ODgiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6ODhENjI4NTA5QjdFMTFFNjhBMDVDNTlBM0NEQzU3ODgiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6skkEkAAAAr0lEQVR42rTVAQ2AIBAFUDABEYxgBCMYgShGMAJRjGAEI9gAQTnHFLjjVLa/nTLfZPtTaa0VX68mmtuXVndN/k1densuHa5ro8Pz43HyaMMwYQAXF3VHOfADTKE1cBLMoRQ4C5bQElwEMTQFoyAFjWFDAamozxzAFQOpKBx5o9attoekunF6iMKC00MM5oAozAWL8BswC8PGwASTMNyEuijmR9pncun8LP/4R+0CDADgc7asw+SGigAAAABJRU5ErkJggg=='), default;
        @include transition-on();
    }

    &-btn {
        display: none;
        position: fixed;
        top: 0;
        left: -#{$sidebar-btn-width};
        width: $sidebar-btn-width;
        height: 100%;
        z-index: 999;
        text-decoration: none;
        text-align: center;
        @include transition-on();

        .ish-page-loaded & {
            animation: ish-sbar-left .3s .3s ease;
            animation-fill-mode: forwards;
        }

        @keyframes ish-sbar-left {
            0% { left: -#{$sidebar-btn-width}; }
            100% { left: 0 }
        }

        &:before {
            position: relative;
            width: 100%;
            margin: 15px 0 0 0 !important;
            padding: 0 !important;
            font-size: 15px;
            text-align: center;
            @include transition-on();
        }

        &__desc {
            transform: rotate(-90deg) translateZ(0);
            transform-origin: bottom left;
            display: block;
            font-size: 14px;
            position: absolute;
            left: 28px;
            bottom: 20px;
            @include transition-on();
            white-space: nowrap;
        }

        &:hover {
            width: $sidebar-btn-width + 10;

            .ish-sbar-btn__desc {
                left: 34px;
            }
        }
    }

    &-close-btn {
        position: absolute;
        top: 0;
        right: -$sidebar-btn-width;
        width: $sidebar-btn-width;
        height: 100%;
        border-left: 1px solid;
        text-align: center;
        text-decoration: none;
        padding-top: 5px;
        font-size: 14px;

        &:before {
            @include transition-on();
            transition: transform .5s ease-in-out;
        }

        &:hover:before {
            transform: rotate(270deg);
            @include transition-on();
        }

        &__desc {
            transform: rotate(-90deg);
            transform-origin: bottom left;
            display: block;
            font-size: 14px;
            position: absolute;
            left: 28px;
            bottom: 20px;
            white-space: nowrap;
        }
    }
}

.ish-sbar-opened,
.ish-navigation--opened {
    overflow: hidden;
}

.ish-sbar-opened {
    // Hide button
    .ish-sbar-btn {
        left: 0;
        animation: ish-sbar-open-anim $trans-delay ease forwards;
    }

    // Open sidebar
    .ish-sbar {
        left: 0;
        display: block;
    }

    // Show overlay
    .ish-sbar-overlay {
        visibility: visible;
        opacity: .75;
    }

    // Slide wrapper
    .ish-wrapper {
        left: $sidebar-width;
    }
}

.ish-sbar-closed {
    .ish-sbar-btn {
        left: -$sidebar-btn-width - 10;
        animation: ish-sbar-close-anim $trans-delay ease forwards;
        animation-delay: $trans-delay;
    }
}

@keyframes ish-sbar-open-anim {
    0% {
        left: 0;
    }
    100% {
        left: -$sidebar-btn-width - 10;
    }
}

@keyframes ish-sbar-close-anim {
    0% {
        left: -$sidebar-btn-width - 10;
    }
    100% {
        left: 0;
    }
}

.ish-icons-group {
    &__icon {
        text-decoration: none;
        margin-right: 10px;
        @include transition-on();
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Header
.ish-header {
    height: $header-height;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;

    &__logo {
        @include main-font(700, 30px, 40px, -2px);
        white-space: nowrap;
        margin-right: 60px;
        width: 100%;
    }

    &__link {
        text-decoration: none;
    }

    &__tagline {
        @include main-font(500, 14px, 25px);
        text-align: right;
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Preloader
.ish-preloader {
    width: $preloader-size;
    height: $preloader-size;
    position: absolute;
    z-index: 999;
    top: calc( 50% - #{$preloader-size} / 2 );
    left: calc( 50% - #{$preloader-size} / 2 );
    animation: preloader-spin 2s linear infinite;

    .ish-page-loaded & {
        display: none;
    }
}

@keyframes preloader-spin {
    0% {
        width: 0;
        left: calc( 50% - #{$preloader-size} / 2 );
    }
    25% {
        width: $preloader-size;
        left: calc( 50% - #{$preloader-size} / 2 );
    }
    50% {
        width: 0;
        left: calc( 50% + #{$preloader-size} / 2 );
    }
    75% {
        width: $preloader-size;
        left: calc( 50% - #{$preloader-size} / 2 );
    }
    100% {
        width: 0;
        left: calc( 50% - #{$preloader-size} / 2 );
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Lead
.ish-lead {
    position: relative;
    margin-top: 1px;
    text-decoration: none;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 50%;
        height: 100%;
        background-color: white;
        display: block;
        left: 0;
        top: 0;
        z-index: 1;
        @include transition-on( width );

        .ish-page-loaded & {
            width: 0;
        }
    }

    &:after {
        left: auto;
        right: 0;
    }

    & > .ish-lead__caption {
        position: absolute;
        top: calc( 100% + 14px);
        left: 20px;
        @include main-font(500, 14px, 25px);

        &:before {
            margin-right: 15px;
        }
    }

    &__headline {
        display: inline-block;
        text-align: right;
        font-size: $headline_font_size;
        line-height: 2;
        width: 100%;
        padding-left: 50px;
        position: relative;
        z-index: 2;
        left: calc( #{$content-margin-right} - ( #{$headline_font_size} / 1.6 ) + 4px );
        opacity: 0;
        margin-left: 30px;
        transition: margin .3s ease, opacity .3s ease;
        @include transition-delay( .3s );

        .ish-page-loaded & {
            margin-left: -4px;
            opacity: 1;
        }
    }

    &__headline-inner {
        box-decoration-break: clone;
        display: inline;
        text-align: right;
        padding: calc( #{$headline_font_size} / 4 ) 0;
        // 35px shadow
    }

    &__image {
        display: table;
        overflow: hidden;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        @include transition-on( opacity );

        .ish-page-loaded & {
            visibility: visible;
            opacity: 1;
        }

        img {
            width: 100%;
        }

        & + .ish-lead__headline {
            position: absolute;
            right: 0;
            bottom: -60px;
        }
    }

    // Animation on lead image
    &.ish-lead--animation-off {
        // dynamic value via JS scrolling count
    }

    &.ish-lead--animation-off {
        img {
            transform: scale(1) !important;
        }
    }

    // Position fixed for headline
    &.ish-lead--fixed-position {
        .ish-lead__headline {
            position: fixed;
            bottom: -22px;
        }
    }
}

// Hide lead headline only if js is available
.js .ish-lead__headline {
    display: none;
}


// ---------------------------------------------------------------------------------------------------------------------
// Content
.ish-content {
    margin: 100px 0;

    &.ish-blog-archive {
        margin: 60px 0;
    }

    // Adjusting grid paddings left and right - to make grid 100% parent
    & > .ish-content__sidebar {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;

        &.ish-last-xs {
            margin-bottom: 0;
            padding-top: $general-gap;
            border-top: solid 1px;
        }

        &.ish-first-xs {
            margin-bottom: $general-gap;
            padding-bottom: $general-gap;
            border-bottom: solid 1px;
        }

        ul {
            li:last-child {
                margin-bottom: 0;
            }
        }
    }

    & > .ish-content__main {
        padding: 0;
    }

    p {
        margin-bottom: $general-gap;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        @include link-underline-on();
    }

    ul, ol {
        margin: 0 0 $general-gap;
        padding: 0 0 0 20px;

        ul, ol {
            margin: 0;
            padding: 0 0 0 $general-gap;
        }

        li {
            padding: 0;
        }
    }

    &__main {
        margin-bottom: $general-gap;
    }

    img {
        max-width: 100%;
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Pagination.
.ish-pagination-container {
    padding: 0;
    margin-bottom: $general-gap*3;
    margin-top: calc( -#{$general-gap} * 1.5 );

    .nav-links {
        display: flex;
        flex-wrap: wrap;
    }

    .ish-page-numbers {
        display: inline-block;
        width: $pag-num-box-size;
        height: $pag-num-box-size;
        line-height: $pag-num-box-size;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        font-size: 14px;
        margin: 0 1px 1px 0;
        @include transition-on();

        &:last-child {
            margin-right: 0;
        }
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Footer
.ish-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 61px;
    width: 100%;
    display: flex;

    &__wrapper {
        width: 100%;
        padding: 0 20px;
        border-top: 1px solid;
        @include main-font(400, 12px, 24px);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__wrapper-inner {

    }

    span {
        margin: 0 10px;
    }

    a {
        @include link-underline-on();
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Sidenav
.ish-sidenav {
    min-width: $sidenav-width;

    &-inner {
        position: fixed;
        width: $sidenav-width;
        height: 100%;
        z-index: 1000;
        right: 0;
        @include transition-on();

        .ish-sbar-opened & {
            right: -#{$sidebar-width};
        }
    }

    // Menu icon
    &-menu {
        text-align: center;
        position: relative;
        z-index: 1;
        height: $header-height + 1;
        border-bottom: 1px solid;
        @include transition-on();

        &__icon {
            font-size: 22px;
            text-decoration: none;
            display: block;
            padding: 16px 0;
            @include transition-on();
            position: relative;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                #nav-icon3:not(.open) span {
                    width: 80%;

                    &:first-child {
                        width: 50%;
                    }
                    &:last-child {
                        width: 100%;
                    }
                }

                #nav-icon3.open {
                }
            }
        }
    }

    // Socials
    &-socials {
        display: none;

        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 75px - $btt-height;
        @include transition-on();

        &__icon {
            font-size: 14px;
            display: block;
            text-decoration: none;
            @include transition-on( transform );
            padding: 5px 0;
            opacity: 0;

            &.animate {
                .ish-page-loaded & {
                    opacity: 1;
                    transition: opacity .5s .6s ease, color .3s 0s ease;
                    animation: ish-social .5s .6s ease-in;
                }

                @keyframes ish-social {
                    0% { transform: scale(1); }
                    70% { transform: scale(1.5); }
                    100% { transform: scale(1); }
                }
            }
        }
    }

    // Back to top
    &-btt {
        position: absolute;
        bottom: -$btt-height;
        text-align: center;
        width: 100%;
        height: $btt-height;
        @include transition-on();

        &__icon {
            font-size: 14px;
            display: block;
            text-decoration: none;
            @include transition-on();
            border-top: 1px solid;
            padding: 16px 0;
        }
    }
}

#nav-icon3 {
    width: 20px;
    height: 14px;
    position: relative;
    margin: 0 auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:first-child { width: 80%; }
        &:last-child { width: 60%; }

        //
        &:nth-child(1) { top: 0; }
        &:nth-child(2), &:nth-child(3) { top: 6px; }
        &:nth-child(4) { top: 12px; }
    }

    // Close icon
    &.open span {
        &:nth-child(1) {
            top: 12px;
            width: 0;
            left: 50%;
        }

        &:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        &:nth-child(4) {
            top: 12px;
            width: 0;
            left: 50%;
        }
    }
}

.ish--back-to-top {
    .ish-sidenav-btt {
        bottom: 0;
    }

    .ish-sidenav-socials {
        bottom: 75px;
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Navigation
.ish-navigation {
    $overflow: 20px;

    position: fixed;
    top: 0;
    left: calc( -100% - #{$overflow} );
    @include transition-on();
    z-index: 99998;

    width: calc( 100% + #{$overflow} );
    height: 100%;

    &__close-btn {
        $cls-btn-wdth: 30px;

        position: absolute;
        top: 0;
        right: $cls-btn-wdth - $overflow/2;
        width: $cls-btn-wdth;
        height: 100%;
        border-left: 1px solid;
        text-align: center;
        text-decoration: none;
        padding-top: 5px;
        font-size: 14px;
        @include transition-on();

        &:before {
            @include transition-on();
            transition: transform .5s ease-in-out;
        }

        &:hover:before {
            transform: rotate(270deg);
            @include transition-on();
        }

        &-desc {
            transform: rotate(-90deg);
            transform-origin: bottom left;
            display: block;
            font-size: 14px;
            position: absolute;
            left: 28px;
            bottom: 20px;
            white-space: nowrap;
        }
    }

    &__logo {
        @include main-font(600, 30px, 40px, -2px);
        text-decoration: none;
        margin-bottom: $general-gap/1.5;
        display: inline-block;
    }

    &__menu {
        padding:
                $general-gap/1.3
                $general-gap+40px
                $general-gap/1.3
                $general-gap/1.3;
        margin: 0;
        height: 100%;
        overflow: auto;
    }

    &__item {
        padding: 0;
        list-style: none;

        & > ul {
            padding: 0 0 0 $general-gap/2;

            li {
                line-height: 25px;
                text-align: left;
                list-style: none;

                a {
                    display: block;
                    text-decoration: none;
                    @include transition-on();
                }
            }
        }
    }

    &__link {
        text-decoration: none;
        @include transition-on();
        z-index: 99998;
    }

    // Resp hide search opener icon
    &__item--search {
        display: none;
    }

    // Resp search
    &__search {
        &-form {
            display: table;
            margin: $general-gap 0;
            width: 100%;
            position: relative;
        }

        &-btn {
            float: right;
            background: none;
            border: none;
            @include transition-on();
            font-size: 26px;
            outline: none;
            position: absolute;
            top: 0;
            right: 0;
            padding: 12px 10px 11px;
        }

        &-field {
            float: left;
            width: 100%;
            border: none;
            outline: none;
            padding: 15px 55px 17px 18px;
        }

        &-close {
            display: none;
        }
    }

    // Resp icons
    &__resp-icon {
        display: inline-block;

        &:not(:last-of-type) {
            margin-right: 25px;
        }

        .ish-sc-icon {
            text-decoration: none;
            font-size: 20px;
        }
    }
}

// Open header navigation
.ish-navigation--opened {
    .ish-navigation {
        left: 0;
    }
}

// Open / replace menu with search form
.ish-navigation--search-active {
    .ish-navigation__menu li {
        visibility: hidden;
        opacity: 0;
        @include transition-on();
    }

    .ish-navigation__menu .ish-navigation__item--search-form {
        visibility: visible;
        opacity: 1;
    }
}

// ---------------------------------------------------------------------------------------------------------------------
// Sticky nav
.ish-nav--sticky {
    // Side hamburger menu
    .ish-sidenav-menu {
        height: $sticky-height;
    }
}


// ---------------------------------------------------------------------------------------------------------------------
// Responsive bottom sticky navigation
.ish-resp-nav {
    $margin: 5px;

    position: fixed;
    left: $margin;
    bottom: $margin;
    width: calc( 100% - ( #{$margin} * 2 ) );
    height: $resp-nav;
    z-index: 999;
    @include transition-on();
    opacity: 1;
    display: flex;
    justify-content: space-between;

    &--hidden {
        bottom: -$resp-nav - $margin;
        opacity: 0;
    }

    &__icon {
        text-decoration: none;
        line-height: $resp-nav;
        padding: 0 15px;

        &--sidebar {
            font-size: 21px;
        }
        &--nav {
            font-size: 33px;
        }
        &--btt {
        }
    }
}