// =====================================================================================================================
// Global


*
{
    box-sizing: border-box;
}


body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include main-font();
}


p {
    margin: 0;

    &:last-child {
        margin-bottom: 0;
    }
}


// Aligned text
.ish-text-align--right {
    text-align: right;
}


// Image align
.ish-align-left {

}


// Google map
.ish-contact-map-in-lead {
    $map-height: 600px;

    .ish-lead__image {
        height: $map-height;
    }

    #map {
        width: 100%;
        height: $map-height;
        z-index: 0;
        position: absolute;

        h1, h2, h3, h4, h5, h6, p, span, div, a,
        .ish-marker-info > *:last-child {
            margin: 0;
        }

        h1, h2, h3, h4, h5, h6, p {
            margin-bottom: 10px;
        }
    }

    .map-marker
    {
        display: none;
    }

    // Move gm controls because of sidenav
    .gmnoprint {
        .gm-style-mtc {
            position: relative;
            left: 29px !important;
        }
    }

}


// Sidebar metadata
.ish-sidebar-mdata {
    list-style: none;
    padding: 0 !important;
    margin: 0;

    &__item {
        margin-bottom: $general-gap;
    }

    &__title {
        display: block;
    }
}


// Sidebar share
.ish-sidebar-share {

    &__desc {
        margin-right: 10px;
    }

    &__list {
        list-style: none;
        padding: 0 !important;
        margin: 0 0 25px !important;
        display: inline-block;

        &-item {
            display: inline;
            margin-right: 16px;
        }

        &-link {

        }
    }
}


// Sidebar pagination
.ish-prev-next {
    padding: 0 !important;
    margin: 0;
    line-height: 18px;

    &__item {
        list-style: none;
        display: inline-block;

        &:hover .ish-prev-next__icon {
        }
    }

    &__link {
        text-decoration: none;
        @include link-underline-on();
        float: left;
        margin: 0 2px;

        &:hover {

        }
    }

    &__icon {
        position: relative;
        top: 2px;
        @include transition-on();
        float: right;
    }

    &--left {
        .ish-prev-next__link {
            float: right;
        }

        .ish-prev-next__icon {
            float: left;
        }
    }

    &--right {
        float: right;
    }
}


// Iframe
iframe {
    border: none;
}


// Image element
.ish-image {
    position: relative;
    margin-bottom: $general-gap;
    display: block;
    border-bottom: none !important;

    &__image {
        max-width: 100%;
        @include transition-on();
        @include fix-blurry-image();
    }

    &__caption {
        $pos: 5px;
        position: absolute;
        bottom: -$pos;
        right: -$pos;
        @include main-font(500, 12px, 18px);
        padding: 5px 9px 6px;
        text-align: right;
        @include transition-on();
        opacity: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        .ish-image__image {
            opacity: $opacity;
        }

        .ish-image__caption {
            opacity: 1;
        }
    }

    // Aligning
    &.ish-align-left, &.ish-align-right {
        margin-bottom: 0;
    }

    &.ish-align-left {
        float: left;
        margin: 0 ( $general-gap / 1.5 ) ( $general-gap / 2 ) 0;
    }

    &.ish-align-right {
        float: right;
        margin: 0 0 ( $general-gap / 2 ) ( $general-gap / 1.5 );
    }
}


// Pre / code
pre, code {
    font-size: 15px;
    line-height: 24px;
}

pre {
    padding: $general-gap / 2;
    margin-bottom: $general-gap;
    white-space: pre-wrap;
}

code {
    padding: 2px 5px 3px;
}


// Blockquote
blockquote {
    margin: 0;
    font-size: 18px;
    font-style: italic;
    margin-bottom: $general-gap;
    position: relative;
    padding: $general-gap calc( #{$general-gap} / 2 );

    $border-width: 100px;

    &:before, &:after {
        content: '';
        position: absolute;
        left: calc( 50% - #{$border-width / 2} );
        border-top: 1px solid;
        width: $border-width;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }

    // Aligning
    &.ish-align-left {

    }

    &.ish-align-center {
        text-align: center;
    }

    &.ish-align-right {
        text-align: right;
    }
}


// Forms
form {
    &:not(.ish-navigation__search-form) {
        > p {
            display: table;
            width: 100%;
            position: relative;
        }

        label {
            position: absolute;
            right: 0;
            top: -3px;
            display: block;
            padding: 17px 15px;
            margin: 0;
            line-height: 16px;
        }

        input, textarea, button {
            width: 100%;
            margin: 0;
            padding: 12px 15px;
            border: none;
            line-height: 16px;
            display: block;

            &:not([type="submit"]) {
                border-bottom: 1px solid;
                @include transition-on();
                outline: none;
            }
        }

        textarea {
            height: 150px;
        }

        [type="submit"] {
            font-weight: 700;
            line-height: 25px;
            @include transition-on();
        }
    }
}


// HR - separator
hr {
    background: none !important;

    // Add margin bottom apart from last-child
    &:not(:last-child) {
        margin-bottom: $general-gap;
    }
}