// =====================================================================================================================
// Google fonts


@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    src: url(font/Work_Sans-normal-400.woff) format('woff');
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    src: url(font/Work_Sans-normal-500.woff) format('woff');
}

@font-face {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 700;
    src: url(font/Work_Sans-normal-700.woff) format('woff');
}